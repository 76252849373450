<template>
  <v-container fluid>
    <Header headerClass="valutare-aria" title="Valutare l'aria" />
    <v-container page>
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Inventario emissioni</h2>
          <v-card flat>
            <v-list-item three-line class="center">
              <v-list-item-content>
                <p>
                  L’Inventario Regionale delle Emissioni in Atmosfera (IREA)
                  fornisce, a un livello di dettaglio comunale, la
                  <strong
                    >stima delle quantità di inquinanti introdotte in atmosfera
                    da sorgenti naturali e/o attività antropiche.</strong
                  >
                </p>
                <p>
                  La sua realizzazione e il suo aggiornamento periodico
                  comportano non solo il reperimento dei dati di base -
                  parametri e fattori di emissione – da molteplici fonti, sia
                  istituzionali che private, ma anche l’applicazione di
                  metodologie di calcolo in continua evoluzione. In sintesi, con
                  IREA è possibile raccogliere sistematicamente i dati relativi
                  ai principali inquinanti, emessi in un anno in una determinata
                  area geografica. È per questo uno strumento di fondamentale
                  importanza per la gestione della qualità dell’aria.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuValutareAria page="inventario-emissioni" />
        </v-col>
      </v-row>
    </v-container>

    <div class="inventario">
      <v-container>
        <v-row justify-center>
          <v-col cols="8" offset="2">
            <p>
              Le stime riguardano le sorgenti classificate secondo la
              nomenclatura
              <strong>SNAP (Selected Nomenclature for Air Pollution)</strong> e
              si riferiscono ai seguenti inquinanti e gas serra: metano
              (CH<sub>4</sub>), monossido di carbonio (CO), anidride carbonica
              (CO<sub>2</sub>), protossido di azoto (N<sub>2</sub>O), ammoniaca
              (NH<sub>3</sub>), composti organici volatili non metanici (NMVOC),
              ossidi di azoto (NO<sub>x</sub>), biossido di zolfo
              (SO<sub>2</sub>), polveri fini di diametro ≤ 10<sub>µ</sub>
              (PM<sub>10</sub>), polveri fini di diametro ≤ 2.5<sub>µ</sub>
              (PM<sub>2.5</sub>).
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!--v-container hexagon>
      <v-row>
        <v-col class="col-md-9 col-12">
          <p class="mt-40">
            Le<strong>stime</strong> riguardano le sorgenti classificate secondo la
            nomenclatura<strong>SNAP (Selected Nomenclature for Air Pollution)</strong> e
            si riferiscono i seguenti inquinanti e gas serra:
          </p>
          <div class="lista">
            <ul class="mt-40">
              <li>metano (CH4),</li>
              <li>monossido di carbonio (CO),</li>
              <li>anidride carbonica (CO2),</li>
              <li>protossido di azoto (N2O),</li>
              <li>ammoniaca (NH3),</li>
              <li>composti organici volatili non metanici (NMVOC),</li>
              <li>ossidi di azoto (NOx),</li>
              <li>biossido di zolfo (SO2),</li>
              <li>polveri fini di diametro ≤ 10µ (PM10),</li>
              <li>polveri fini di diametro ≤ 2.5µ (PM2.5)</li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container-->
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuValutareAria from "@/views/valutare-aria/MenuValutareAria";

export default {
  components: {
    Header,
    MenuValutareAria
  }
};
</script>
